var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"90%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("modal.plugin.title"))+" ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticStyle:{"max-height":"600px","overflow":"hidden"},attrs:{"cols":"3"}},[_c('v-sheet',{staticClass:"pa-1",staticStyle:{"background-color":"rgb(174, 19, 19) !important"}},[_c('v-text-field',{staticClass:"searchField",attrs:{"label":"Search Plugin","dark":"","flat":"","solo-inverted":"","hide-details":"","clearable":"","clear-icon":"mdi-close-circle-outline"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-treeview',{staticStyle:{"cursor":"pointer","overflow-y":"scroll","height":"500px"},attrs:{"items":_vm.plugins_sorted,"search":_vm.search,"open":_vm.open,"activatable":"","open-all":"","active":_vm.active},on:{"update:open":function($event){_vm.open=$event},"update:active":function($event){_vm.active=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.icon))])]}}])})],1),_c('v-col',{attrs:{"cols":"9"}},[(!_vm.selected)?_c('div',{staticClass:"text-h6 grey--text font-weight-light",staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t("modal.plugin.search.select"))+" ")]):_c('v-card',{key:_vm.selected.id,staticClass:"pt-6 mx-auto",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.selected.name)+" ")]),_c('v-card-text',[_c('div',{staticStyle:{"padding-bottom":"2em"},domProps:{"innerHTML":_vm._s(_vm.selected.description)}}),_c('Parameters',{attrs:{"parameters":_vm.selected.parameters}}),(_vm.selected.optional_parameters &&
                _vm.selected.optional_parameters.length > 0
                )?_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"expand-icon":"mdi-menu-down"}},[_vm._v(" Advanced Options ")]),_c('v-expansion-panel-content',[_c('Parameters',{attrs:{"parameters":_vm.selected.optional_parameters}})],1)],1)],1):_vm._e()],1),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-btn',{on:{"click":function($event){return _vm.runPlugin(
                  _vm.selected.plugin,
                  _vm.selected.parameters,
                  _vm.selected.optional_parameters
                )}}},[_vm._v(_vm._s(_vm.$t("modal.plugin.run")))])],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-btn',{on:{"click":function($event){_vm.dialog = false}}},[_vm._v(_vm._s(_vm.$t("modal.plugin.close")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }