var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"1000"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v(_vm._s(_vm.$t("timelineSegment.title")))]),_c('v-card-text',[_c('v-form',{on:{"submit":_vm.submit}},[(_vm.show)?_c('v-combobox',{staticClass:"mb-9",attrs:{"clearable":"","multiple":"","autofocus":"","items":_vm.allExistingAnnotations,"search-input":_vm.search,"item-text":"name"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":_vm.onAnnotationChange,"keydown":_vm.onKeydown},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" No results matching \""),_c('strong',[_vm._v(_vm._s(_vm.search))]),_vm._v("\". Press "),_c('kbd',[_vm._v("enter")]),_vm._v(" to create a new one ")])],1)],1)]},proxy:true},{key:"item",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
return [_c('v-chip',[_c('v-btn',{staticClass:"mr-1",attrs:{"disable":"","icon":"","x-small":"","color":item.color}},[_c('v-icon',[_vm._v(_vm._s("mdi-palette"))])],1),(item.category)?_c('v-btn',{staticClass:"mr-1",attrs:{"disable":"","x-small":"","color":item.color}},[_vm._v(_vm._s(item.category.name)+" ")]):_vm._e(),_c('span',[_vm._v(_vm._s(item.name))])],1)]}},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var index = ref.index;
return [_c('v-chip',[_c('v-menu',{attrs:{"top":"","nudge-bottom":"105","nudge-left":"16","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"disable":"","icon":"","x-small":"","color":item.color}},on),[_c('v-icon',[_vm._v(_vm._s("mdi-palette"))])],1)]}}],null,true),model:{value:(item.colorMenu),callback:function ($$v) {_vm.$set(item, "colorMenu", $$v)},expression:"item.colorMenu"}},[_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-color-picker',{attrs:{"flat":""},model:{value:(item.color),callback:function ($$v) {_vm.$set(item, "color", $$v)},expression:"item.color"}})],1)],1)],1),_c('v-menu',{attrs:{"top":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!item.category)?_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"disable":"","icon":"","x-small":"","color":item.category ? item.category.color : ''}},on),[_c('v-icon',[_vm._v(_vm._s("mdi-menu"))])],1):_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"disable":"","x-small":"","color":item.color}},on),[_vm._v(" "+_vm._s(item.category.name)+" ")])]}}],null,true),model:{value:(item.categoryMenu),callback:function ($$v) {_vm.$set(item, "categoryMenu", $$v)},expression:"item.categoryMenu"}},[_c('v-card',[_c('v-card-text',[_c('v-combobox',{attrs:{"clearable":"","items":_vm.annotationCategories,"flat":""},on:{"change":function($event){return _vm.onAnnotationCategoryChange(item)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}],null,true),model:{value:(item.category),callback:function ($$v) {_vm.$set(item, "category", $$v)},expression:"item.category"}})],1)],1)],1),_c('span',[_vm._v(_vm._s(item.name))]),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.onDeleteItem(index)}}},[_c('v-icon',[_vm._v(_vm._s("mdi-close"))])],1)],1)]}}],null,false,589131755),model:{value:(_vm.annotations),callback:function ($$v) {_vm.annotations=$$v},expression:"annotations"}}):_vm._e(),_c('v-btn',{staticClass:"mr-4",attrs:{"disabled":_vm.isSubmitting},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t("timelineSegment.update"))+" ")]),_c('v-btn',{on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("timelineSegment.close")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }