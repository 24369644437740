var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"offset-y":"","bottom":"","left":"","width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var menu = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-n2",attrs:{"tile":"","text":"","title":_vm.$t('plugin.menu.title')}},'v-btn',attrs,false),menu),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-history")]),(_vm.numRunningPlugins > 0)?_c('v-badge',{attrs:{"color":"accent","content":_vm.numRunningPlugins}},[_vm._v(" History ")]):_c('span',[_vm._v(" History ")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"items-per-page":10,"headers":_vm.headers,"items":_vm.pluginRuns,"item-key":"id"},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"color":_vm.progressColor(value)}},[_vm._v(" "+_vm._s(value))])]}},{key:"item.progress",fn:function(ref){
var value = ref.value;
return [_c('v-progress-linear',{attrs:{"value":value * 100,"height":"8"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }